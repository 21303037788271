import '@superlanding/beyond/dist/beyond.css'
import './sessions-new.scss'
import { $, on, Btn } from '@superlanding/beyond'
import Rails from '@rails/ujs'

Rails.start()

const lineLoginDom = $('#line-login-btn')
const fbLoginDom = $('#fb-login-btn')

const lineLoginBtn = new Btn(lineLoginDom)
const fbLoginBtn = new Btn(fbLoginDom)

on(lineLoginDom, 'click', () => lineLoginBtn.setLoading(true))
on(fbLoginDom, 'click', () => fbLoginBtn.setLoading(true))
